const realVoicesData = [
  {
    heading: '"I\'d count this as an employee benefit."',
    fullHeading:
      "How we partnered with Sodexo to analyse team scores, identifying urgent areas where employees needed to focus and prioritise self-care, experience and work design.",
    person: "Doug Lowrie",
    designation: "Global Workplace Dir.",
    company: "Sodexo",
    profileImg: "doug1",
    coverImg: "doug2",
    video: "doug",
    description:
      '"I want to see my personal SHAPE scores in comparison to the aggregated score of my team, where I’m contributing and where I need to focus more energy. At the organisational level, it’s important to see where the burning platforms are so more investment can be targeted with maximum effect."',
  },
  {
    heading: '"We\'d never done something as analytical as SHAPE."',
    fullHeading:
      "How we helped EFG Furniture outline various plans for internal initiatives, including office redesign, health-focused initiatives, and enhancing communication skills within the business.",

    person: "Jennifer Wakefield  ",
    designation: "Marketing Manager",
    company: "EFG Furniture",
    profileImg: "jennifer2",
    coverImg: "jennifer1",
    video: "jennifer",
    description:
      '"Sharing the results with the entire team has led to more open discussions about both the strengths and areas needing improvement. The structured insights provided a starting point for meaningful conversations."',
  },
  {
    heading: '"It enabled us to create actionable steps from the data."',
    fullHeading:
      "How we supported Pareto FM in building trust through action by spotlighting eNPS scores and vital employee health areas: sleep, stress, and nutrition.",

    person: "Emma Wilson ",
    designation: "Social Impact Dir.",
    company: " Pareto FM",
    profileImg: "emma2",
    coverImg: "emma1",
    video: "emma",
    description:
      "\"SHAPE has been a game changer. It really helps in any business case. If you want to bring a new benefit, if you want to change the structure, if you want bring out a new policy. It's really the full package. It's fantastic.\"",
  },
  {
    heading: '"The breadth and depth of SHAPE is unique."',
    fullHeading:
      "How we helped Bob anonymously measure employee experience, gain insights into underlying issues at work, and address them effectively.",

    person: "Bob Stamegna",
    designation: "Head of HR",
    company: "CN Foods",
    profileImg: "bob2",
    coverImg: "bob1",
    video: "bob",
    description:
      '"What you really want is for your staff members, your teams to be flourishing, happy, well-rounded people having a dynamic life they’re enjoying… SHAPE really provided the data that helped us get to that."',
  },
]

export { realVoicesData }
