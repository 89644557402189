import React, { useState } from "react"
import "./rebrand-carousel.scss"
import ArrowRight from "../../images/learnmore/arrowRight-light.svg"
import ArrowLeft from "../../images/learnmore/arrowLeft-light.svg"

const RebrandCarousel = ({ data, map, shapeScience, dataPrivacy }) => {
  const [currentPage, setCurrentPage] = useState(0)
  let carouselTabsWrapper = React.useRef()
  const onChangeCarousel = (e, index) => {
    let contentText = document.getElementById("card-content-text")
    let contentImg = document.getElementById("card-content-img")
    contentText.style.opacity = 0
    contentImg.style.opacity = 0

    setTimeout(() => {
      setCurrentPage(null)
      setCurrentPage(index)
      contentText.style.opacity = 1
      contentImg.style.opacity = 1
    }, 100)
  }

  const handleHorizantalScroll = (element, speed, distance, step) => {
    let scrollAmount = 0
    const slideTimer = setInterval(() => {
      element.scrollLeft += step
      scrollAmount += Math.abs(step)
      if (scrollAmount >= distance) {
        clearInterval(slideTimer)
      }
    }, speed)
  }

  return (
    <div
      className={`rebrandcarouselWrapper ${
        dataPrivacy ? "dataPrivacyCarousel" : ""
      }`}
    >
      <div className="carouselcardtabswrapper">
        <img
          src={ArrowLeft}
          className="carouselArrow"
          onClick={() => {
            if (currentPage >= 1) {
              onChangeCarousel(null, currentPage - 1)
              handleHorizantalScroll(
                carouselTabsWrapper?.current,
                10,
                dataPrivacy ? 280 : 300,
                -20
              )
            }
          }}
        />
        <div className={`rebrandscarouselcardtabs`} ref={carouselTabsWrapper}>
          {data?.map((item, index) => (
            <div
              id={`tab-${index}`}
              className={`${currentPage === index ? "fill" : ""}`}
              onClick={(e) => onChangeCarousel(e, index)}
            >
              {item.title}
            </div>
          ))}
        </div>

        <img
          src={ArrowRight}
          className="carouselArrow"
          onClick={() => {
            if (currentPage < data?.length - 1) {
              onChangeCarousel(null, currentPage + 1)
              handleHorizantalScroll(
                carouselTabsWrapper?.current,
                10,
                dataPrivacy ? 280 : 300,
                20
              )
            }
          }}
        />
      </div>
      <div className="rebrandcarouselcardwrapper">
        <div id="carousel-card" className={`carouselcard `}>
          {data && currentPage !== null && currentPage >= 0 && (
            <div className="carouselcardcontent">
              <div
                id="card-content-text"
                className="carouselcardtext cssanimation"
              >
                <div className="carouselcardheading">
                  {data[currentPage].title}
                </div>

                <div
                  className="carouselcarddesc"
                  dangerouslySetInnerHTML={{ __html: data[currentPage].desc }}
                />
              </div>
              <div
                id="card-content-img"
                className={`carouselcardimg cssanimation`}
              >
                {!dataPrivacy &&
                !["Hybrid Flexibility"].includes(data[currentPage].title) ? (
                  <video autoPlay loop muted playsInline loading="lazy">
                    <source
                      src={data[currentPage].contentImg}
                      type="video/mp4"
                    />
                    Sorry, your browser doesn't support videos.
                  </video>
                ) : (
                  <img
                    src={data[currentPage].contentImg}
                    alt={data[currentPage].title}
                  />
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default RebrandCarousel
