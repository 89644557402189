import { lazy } from "react"
const getOfferingImages = () => {
  return {
    measurement: lazy(() =>
      import("../../../images/home/offering/measurement.svg")
    ),
    automation: lazy(() =>
      import("../../../images/home/offering/automation.svg")
    ),
    yn: lazy(() => import("../../../images/home/offering/yn.svg")),
    reports: lazy(() => import("../../../images/home/offering/reports.svg")),
    proscore: lazy(() => import("../../../images/home/offering/proscore.svg")),
    healthage: lazy(() =>
      import("../../../images/home/offering/healthage.svg")
    ),
    strength: lazy(() => import("../../../images/home/offering/strength.svg")),
    eap: lazy(() => import("../../../images/home/offering/eap.svg")),
    analytics: lazy(() =>
      import("../../../images/home/offering/analytics.svg")
    ),
    advantage: lazy(() =>
      import("../../../images/home/offering/advantage.svg")
    ),
    hybrid: lazy(() => import("../../../images/home/offering/hybrid.png")),
    bestpractice: lazy(() =>
      import("../../../images/home/offering/bestpractices.svg")
    ),
    shapekit: lazy(() => import("../../../images/home/offering/shapekit.svg")),
    heatmap: lazy(() => import("../../../images/home/offering/heatmap.svg")),
    pulse: lazy(() => import("../../../images/home/offering/pulse.png")),
  }
}

export default getOfferingImages
