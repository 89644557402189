export const offeringCards = [
  {
    heading: "Measurement",
    desc: "The experience data you need most. One assessment covers 12 key topics: culture, health, satisfaction and more. We call them Explorers.",
    img: "measurement",
  },
  {
    heading: "Automation ",
    desc: "We’ve done the hard work. Automatically get pre-written messages you can customise and send on to drive survey participation.",
    img: "automation",
  },
  {
    heading: "Your Network ",
    desc: "Build your network in minutes and discover the real team challenges that need attention.",
    img: "yn",
  },
  {
    heading: "Instant Reports",
    desc: "Customised reports at your fingertips with guidance for every employee, manager, and executive.",
    img: "reports",
  },
  {
    heading: "SHAPE ProScore",
    desc: "Setting a new standard, ProScore highlights struggling, striving and flourishing at personal, team, and company levels. It's the gateway to flourishing!",
    img: "proscore",
  },
  {
    heading: "Health Age Scorecard ",
    desc: "Health impacts work. See how much and the where you can make lifestyle changes that matter.",
    img: "healthage",
  },
  {
    heading: "Psychometric Strength",
    desc: "Safeguard mental well-being by taking proactive action against stress, anxiety and depression.",
    img: "strength",
  },
  {
    heading: "Signpost to EAP",
    desc: "Confidentially direct employees that need support to your existing health provider.",
    img: "eap",
  },
  {
    heading: "SHAPE Analytics",
    desc: " Uncover the factors behind employee experience by highlighting key patterns and priority opportunities",
    img: "analytics",
  },
  {
    heading: "Time to Gain",
    desc: "Breakthrough reporting highlights the time and money value of your investment in improving SHAPE scores",
    img: "advantage",
  },

  {
    heading: "Hybrid Report ",
    desc: "Drive your hybrid strategy with confidence. Home or office, discover your team's best productivity habitat. ",
    img: "hybrid",
  },
  {
    heading: "Best Practices",
    desc: "Guidance resources for everyone, including managers and executive teams. Tailored advice to help improve every aspect of experience at work.",
    img: "bestpractices",
  },
  {
    heading: "SHAPE Kit ",
    desc: "Factsheets, worksheets and survey participation boosting resources for every employee",
    img: "shapekit",
  },
  {
    heading: "The Heat Map ",
    desc: " Powerful interactive dashboards exclusively for management to pinpoint issues and facilitate precision action.​",
    img: "heatmap",
  },
  {
    heading: "Survey Rounds",
    desc: "Resurvey and see what’s changed, and what needs your undivided attention.",
    img: "pulse",
  },
]
