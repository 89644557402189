import React, { useState } from "react"
import "./realVoices.scss"
import Doug2 from "../../images/learnmore/doug2.png"
import Doug1 from "../../images/learnmore/doug1.png"
import Bob1 from "../../images/learnmore/bob.png"
import Bob2 from "../../images/learnmore/bob2.png"
import Emma1 from "../../images/learnmore/emma.png"
import Emma2 from "../../images/learnmore/emma2.jpg"
import Jennifer1 from "../../images/learnmore/jennifer.png"
import Jennifer2 from "../../images/learnmore/jennifer2.png"
import Cross from "../../images/learnmore/cross.svg"
import WatchNow from "../../images/learnmore/watchnow.svg"
import BobVoice from "../../images/learnmore/casestudy/bob.mp4"
import DougVoice from "../../images/learnmore/casestudy/doug.mp4"
import EmmaVoice from "../../images/learnmore/casestudy/emma.mp4"
import JenniferVoice from "../../images/learnmore/casestudy/jennifer.mp4"

import { realVoicesData } from "./helper"

const voicesAssetsMap = {
  doug1: Doug1,
  doug2: Doug2,
  bob1: Bob1,
  bob2: Bob2,
  emma1: Emma1,
  emma2: Emma2,
  jennifer1: Jennifer1,
  jennifer2: Jennifer2,
  bob: BobVoice,
  doug: DougVoice,
  emma: EmmaVoice,
  jennifer: JenniferVoice,
}
const RealVoices = () => {
  const deactivateCards = (e) => {
    setActiveVoice(null)
    if (!e) var e = window.event
    e.cancelBubble = true
    if (e.stopPropagation) e.stopPropagation()
  }
  const [activeVoice, setActiveVoice] = useState(null)
  let voicesCards = realVoicesData?.map((card, index) => (
    <div
      onClick={() => setActiveVoice(card.heading)}
      className={`realVoicesCard  ${
        activeVoice
          ? activeVoice === card.heading
            ? "activeVoiceCard"
            : "hideVoices"
          : ""
      }`}
    >
      <img
        onClick={(e) => {
          deactivateCards(e)
        }}
        className="closeActiveCard"
        src={Cross}
        alt="close"
      />
      <div className="realVoicesCardImgWrapper">
        {activeVoice === card.heading ? (
          <video playsInline controls loading="lazy">
            <source src={voicesAssetsMap[card.video]} type="video/mp4" />
            Sorry, your browser doesn't support videos.
          </video>
        ) : (
          <img src={voicesAssetsMap[card.coverImg]} alt="Doug" />
        )}
      </div>
      <div className="realVoicesCardContent">
        {activeVoice === card.heading ? (
          <>
            <h3>{card.fullHeading}</h3>
            <hr />
            <h2>{card.description}</h2>
          </>
        ) : (
          <h3>{card.heading}</h3>
        )}
        <div className="realVoicesCardProfile">
          <div className="realVoicesCardProfileImgWrap">
            <img src={voicesAssetsMap[card.profileImg]} alt="Doug" />
          </div>
          <div className="realVoicesCardProfileDetails">
            <p className="realVoicesCardProfileName">{card.person}</p>
            <p className="realVoicesCardProfileDesignation">
              {card.designation}
            </p>
            <p className="realVoicesCardProfileCompany">{card.company}</p>
          </div>
          <div className="realVoicesCardWatchNow">
            <img src={WatchNow} alt="Watch Now" />
          </div>
        </div>
      </div>
    </div>
  ))

  return (
    <div className="realVoices">
      <div className="realVoicesFlex">
        <h1>The SHAPE Difference</h1>
        <p>Hear our clients for yourself.</p>
        <div className="realVoicesCardFlex">{voicesCards}</div>
      </div>
      <hr className="realVoiceBottomBorder" />
    </div>
  )
}

export default RealVoices
