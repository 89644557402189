import React from "react"
import "./address-challenges.scss"
import Link from "gatsby-link"
import Hybrid from "../../images/home/address-challenges/hybrid.svg"
import Explorers from "../../images/home/address-challenges/retention-new.mp4"
import Retention from "../../images/home/address-challenges/retention.mp4"
import Mental from "../../images/home/address-challenges/mental.mp4"
import Guidance from "../../images/home/address-challenges/guidance.mp4"
import DEI from "../../images/home/address-challenges/dei.mp4"
import RebrandCarousel from "../rebrand-carousel/rebrand-carousel"

const AddressChallenges = () => {
  const carouselDataHome = [
    {
      title: "Hybrid Flexibility",
      desc: "Optimising office participation? Reshaping WFH experiences? <b>Flourishing is the gateway to hybrid balance.</b>",
      contentImg: Hybrid,
    },
    {
      title: "Operational Inefficiency",
      desc: "SHAPE enables you to enhance the factors that contribute most to a resourceful workforce.",
      contentImg: Mental,
    },
    {
      title: "Retention",
      desc: "Battling staff turnover? Move beyond eNPS. Flourishing employees are more loyal to you, love their work and support their teams.",
      contentImg: Explorers,
    },
    {
      title: "Transformation",
      desc: "Flourishing is the fuel that supports effective change. Ensure you're not held back by a flourishing deficit.",
      contentImg: Retention,
    },

    {
      title: "DEI & ESG",
      desc: "Understand the prominent drivers of social impact and positive DEI programs through flourishing employees.",
      contentImg: DEI,
    },
    {
      title: "Layoffs",
      desc: "Support employees through the uncertainty created by layoffs. Help them weather the changes through a flourishing mindset.",
      contentImg: Guidance,
    },
  ]

  return (
    <div className="acSection" id="address-business-challenges">
      <div className="acContentWrapper">
        <h1>Address Business Challenges Faster</h1>
        <p>
          Flourishing employees become your secret advantage against the biggest
          business issues in the world today.
        </p>
        <RebrandCarousel data={carouselDataHome} />
      </div>
    </div>
  )
}
export default AddressChallenges
