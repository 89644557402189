import React from "react"
import "./flourishing-mindset.scss"
import { Link } from "gatsby"

export const FlourishingMindset = () => {
  return (
    <div className="flourishingMindsetContainer">
      <div className="flourishingMindsetContent">
        <h1>Build a flourishing mindset across your organisation.</h1>
        <Link to="/launch">Get Started Today</Link>
      </div>
    </div>
  )
}
