import React from "react"
import "./learnmore.scss"
import Layout from "../components/layout"
import RealVoices from "../components/realVoices/realVoices"
import SEO from "../components/seo"
import ShapeOffering from "../components/shape-offering/shape-offering"
import AddressChallenges from "../components/address-challenges/address-challenges"
import { FlourishingMindset } from "../components/flourishing-mindset/flourishing-mindset"

const LearnMore = () => {
  return (
    <Layout>
      <div className="pageBackground">
        <SEO title="Learn More" />
        <RealVoices />
        <AddressChallenges />
        <FlourishingMindset />
        <ShapeOffering />
      </div>
    </Layout>
  )
}

export default LearnMore
